/* General */

.photo {
  width: 400px
}

@media screen and (max-width: 600px) {
  .photo {
    width: 75%;
  }
}

.landscape {
  width: 75%
}

@media screen and (max-width: 600px) {
  .landscape {
    width: 100%;
  }
}

html {
  text-align: center;
  min-width: 320px;
  background-color: #E8FFFB;
  margin: 0 20px;
  margin-bottom: 60px;
}

@media screen and (max-width: 600px) {
  html {
    margin: 0 5px
  }
}

a {
  text-decoration: none;
  color: black;
}

::-webkit-scrollbar {
  display: none;
}

hr.solid {
  border-top: rgb(59, 103, 114) solid 2px;
}

.textLink {
  font-weight: 600;
}

/* Home Page Styling */
.homePage {
  display: flex;
  height: 80vh;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .homePage {
    flex-direction: column;
    height: 100%
  }
}

.homeSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  height: 100%;
}

.homeQuarter {
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  height: 100%;
}

.landingTitle {
  font-size: 100px;
  font-style: italic;
  align-items: center;
}

.favourites {
  margin-bottom: 40px;
}

.favouritesLink {
  border-style: solid;
  border-width: 3px;
  border-color: black;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b0ebe1;
  border-radius: 5px;
}

.homeText {
  margin-bottom: 20px;
}
/* End Home Page Styling */
/* Books Styling */

.allBooks {
  width: 100%;
}

.bookSelectors {
  margin: 0 auto;
}

#bookSearch {
  max-width: 700px;
}

.themeSelectors {
  width: 75%;
  margin: 10px auto;
  display: flex;
  justify-content: space-around;
}

.themeSelectors input {
  margin-right: 5px;
}

.tileSquare {
  display: inline-grid;
  margin: 5px;
  width: 24%;
  height: 300px;
  border-style: solid;
  border-width: 3px;
  border-color: black;
  border-radius: 5px;
  min-width: 150px;
  box-shadow: 2px 5px 5px;
}

@media screen and (max-width: 600px) {
  .tileSquare {
    max-height: 200px
  }
}

.tileTitle {
  color: black;
}

.tileImage {
  margin: 0 auto;
  width: 50%;
  height: 90%;
  object-fit: contain;
  overflow: hidden;
  min-width: 100px;
}

/* End Books Styling */
/* Book Page Styling*/

.bookPageDiv {
  display: flex;
}

.bookPageDetails {
flex: 1;
}

.bookPageImageDiv {
flex: 1;
margin-top: 15px;
}

.bookPageImage {
width: 250px;
}

.bookPageQuote {
  font-style: italic;
  font-size: larger;
}

.bookParagraph {
  margin-bottom: 15px;
}

@media (max-width: 1000px) {
  .themeSelectors {
    display: contents;
  }
}

@media (max-width: 500px) {
  .bookPageDiv {
    flex-direction: column-reverse;
  }

  .bookPageImage {
    margin: 0 auto;
    justify-content: center;
    }
}

/* End Book Page Styling*/
/* React Page Styling */

.reactHead {
  width: 95%;
  background-color: rgb(163, 255, 255);
}

.reactBody {
  width: max-content;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  max-width: 95%;
}

.reactInput {
  width: 90px;
  text-align: center;
}

.checkoutColumn {
  width: 98px;
  height: max-content;
  background-color: yellow;
  float: left;
  margin: 2px;
}

.checkoutHead {
  width: 100px;
  height: 100px;
  background-color: pink;
  border: 1px solid black;
  border-radius: 5%;
  display: flex;
  justify-content:center;
  align-items:center;
}

.checkoutItem {
  width: 100px;
  height: 100px;
  background-color: rgb(133, 133, 212);
  border-radius: 5%;
  display: flex;
  justify-content:center;
  align-items:center;
  border: 1px solid black;
}

/* End React Page Styling*/
/* Sudoku Page Styling */ 

.sudokuCell {
  width: 35px;
  height: 35px;
  text-align: center;
}

.sudokuButtons button {
  margin: 20px;
}

.borderBottom {
  border-bottom: solid;
}

.borderRight {
  border-right: solid;
}

/* End Sudoku Page Styling */ 
